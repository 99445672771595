body {
  background-color: #3C3C3B !important;
  font-family: 'Teko', sans-serif;
}

.table {
  background: none !important;
}
.table tr th {
  background: black;
  color: #00FF00;
  font-size: 32px;
}
.table tr td {
  background: none !important;
  color: #00FF00;
  font-size: 32px;
}

h1 {
  color: #00FF00;
}